import CustomSelect from "components/utils/CustomSelect";
import { connect } from "react-redux";
import { useState } from "react";

function ResumeCart({ togglePopup, carrito, direcciones, planta, onOrderSubmit }) {
  const [referenciaPedido, setReferenciaPedido] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);

  const addressOptions = direcciones
    ? direcciones.map((direccion) => ({
        value: direccion.COD_DIRECCION,
        label:
          direccion.DIRECCION +
          ", " +
          direccion.COD_POSTAL +
          " " +
          direccion.MUNICIPIO +
          " (" +
          direccion.PROVINCIA +
          ")",
      }))
    : [];

  const handleAddressChange = (selectedOption) => {
    setSelectedAddress(selectedOption);
  };

  const handleReferenciaChange = (event) => {
    setReferenciaPedido(event.target.value);
  };

  const isOrderReady = selectedAddress && referenciaPedido.trim() !== "";

  const calculateTotalBruto = () => {
    return carrito
      ? carrito
          .reduce(
            (total, product) => total + product.PRECIOB * product.cantidad,
            0
          )
          .toFixed(2)
      : 0;
  };

  const calculateTotalDescuentos = () => {
    return carrito
      ? carrito
          .reduce(
            (total, product) =>
              total +
              (product.PRECION - product.PRECION / 1.2) * product.cantidad,
            0
          )
          .toFixed(2)
      : 0;
  };

  const calculateTotalImponible = (totalBruto, totalDescuentos) => {
    return (totalBruto - totalDescuentos).toFixed(2);
  };

  const calculateImpuestos = (totalImponible) => {
    return (totalImponible * 0.21).toFixed(2);
  };

  const calculateTotal = (totalImponible, impuestos) => {
    return (parseFloat(totalImponible) + parseFloat(impuestos)).toFixed(2);
  };

  const calculateTotalArticulos = () => {
    const cantidadUnidades = carrito
      ? carrito.reduce((total, product) => total + product.cantidad, 0)
      : 0;
    const numeroArticulos = carrito ? carrito.length : 0;
    return `${cantidadUnidades} (+${numeroArticulos})`;
  };

  const totalBruto = calculateTotalBruto();
  const totalDescuentos = calculateTotalDescuentos();
  const totalImponible = calculateTotalImponible(totalBruto, totalDescuentos);
  const impuestos = calculateImpuestos(totalImponible);
  const total = calculateTotal(totalImponible, impuestos);
  const totalArticulos = calculateTotalArticulos();

  const handleOrderSubmit = () => {
    if (isOrderReady) {
      onOrderSubmit(selectedAddress.value, referenciaPedido); // Llama a la función recibida por props
      togglePopup(); // Cierra el popup
    } else {
      alert(
        "Por favor, completa la dirección de entrega y la referencia del pedido."
      );
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[999] overflow-auto">
      <div className="bg-white p-4 rounded-lg w-10/12 h-auto lg:h-5/6 max-h-screen overflow-auto">
        <h1 className="font-semibold w-1/4 text-left text-2xl lg:text-4xl ml-2 mt-4">
          Resumen
        </h1>
        <div className="flex flex-col mt-6 ml-2">
          <div className="flex flex-col lg:flex-row justify-start items-center h-auto lg:h-28 mb-8 lg:mb-4 lg:space-x-4">
            <div className="w-full lg:w-3/12 z-40 lg:z-20">
              {!selectedAddress ? (
                <p className="text-red-600 text-end text-4xl -mb-8">*</p>
              ) : (
                ""
              )}
              <label
                className="block text-gray-700 text-sm lg:text-base font-bold mb-2 pb-2 border-b-2 border-black"
                htmlFor="deliveryAddress"
              >
                Direcciones de entrega
              </label>
              <CustomSelect
                options={addressOptions}
                onChange={handleAddressChange}
                classNamePrefix={!selectedAddress ? "input-error" : ""}
              />
            </div>
            <div className="w-full mt-2 lg:mt-0 lg:w-3/12 z-30 lg:z-20">
              {!referenciaPedido  ? (
                <p className="text-red-600 text-end text-4xl -mb-8">*</p>
              ) : (
                ""
              )}
              <label
                className="block text-gray-700 text-sm lg:text-base font-bold mb-1 pb-2 border-b-2 border-black"
                htmlFor="orderReference"
              >
                Referencia del pedido
              </label>
              <input
                type="text"
                id="orderReference"
                className="border border-gray-200 rounded w-full text-blue-700"
                placeholder="Ref. pedido"
                value={referenciaPedido}
                onChange={handleReferenciaChange}
              />
            </div>
            <div className="w-full mt-2 lg:mt-0 lg:w-3/12 lg:mb-2 z-20 lg:z-20">
              <label
                className="block text-gray-700 text-sm lg:text-base font-bold mb-4 pb-2 border-b-2 border-black"
                htmlFor="location"
              >
                Planta
              </label>
              <p className="text-blue-700">{planta}</p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mt-8">
            <div className="w-full lg:w-10/12 overflow-y-auto hide-scrollbar max-h-48 md:max-h-96">
              <table className="min-w-full bg-white text-xs md:text-sm">
                <thead className="sticky top-0 bg-white z-10 shadow-sm shadow-black">
                  <tr>
                    <th className="py-2 w-16"></th>
                    <th className="py-2 w-96">Artículo</th>
                    <th className="py-2 w-8">Observaciones</th>
                    <th className="py-2 w-12">Precio</th>
                    <th className="py-2 w-8">PVP</th>
                    <th className="py-2 w-8">Cantidad</th>
                    <th className="py-2 w-8">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {carrito &&
                    carrito.map((product, index) => (
                      <tr key={index}>
                        <td className="py-2 text-center">
                          <div className="flex justify-center items-center">
                            <img
                              src={`data:image/jpeg;base64,${product.image}`}
                              alt={product.DESCRIPCION}
                              className="h-16 w-16 object-cover"
                            />
                          </div>
                        </td>
                        <td className="px-4 py-4 text-justify text-blue-600">
                          {product.COD_ARTICULO} - {product.DESCRIPCION}
                        </td>
                        <td className="px-4 py-4 text-center">
                          <button className="text-blue-600 hover:text-blue-800">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                              />
                            </svg>
                          </button>
                        </td>
                        <td className="flex px-4 py-7 items-center justify-between text-center text-sm space-x-2">
                          <p className="text-red-600 line-through">
                            {parseFloat(product.PRECION).toFixed(2)} €
                          </p>
                          <p className="font-bold">
                            {parseFloat(product.PRECION / 1.2).toFixed(2)} €
                          </p>
                          <p className="text-white bg-green-600 p-0.5">-20%</p>
                        </td>
                        <td className="px-4 py-4 text-center">
                          {parseFloat(product.PRECIOB).toFixed(2)} €
                        </td>
                        <td className="px-4 py-4 text-center">
                          {product.cantidad}
                        </td>
                        <td className="px-4 py-4 text-center">
                          {(product.PRECION * product.cantidad).toFixed(2)} €
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="w-full lg:w-2/12 lg:ml-4 mt-4 lg:mt-0 overflow-y-auto hide-scrollbar max-h-44 md:max-h-96">
              <div className="text-xs md:text-sm grid grid-cols-2 gap-1 md:block">
                <div className="flex flex-col md:flex-row justify-between mt-0.5  bg-gray-200 p-4">
                  <span>Bruto</span>
                  <span>{totalBruto} €</span>
                </div>
                <div className="flex flex-col md:flex-row justify-between mt-0.5  bg-gray-200 p-4">
                  <span>Descuentos</span>
                  <span>{totalDescuentos} €</span>
                </div>
                <div className="flex flex-col md:flex-row justify-between mt-0.5  bg-gray-200 p-4">
                  <span>B. Imponible</span>
                  <span>{totalImponible} €</span>
                </div>
                <div className="flex flex-col md:flex-row justify-between mt-0.5  bg-gray-200 p-4">
                  <span>Impuestos</span>
                  <span>{impuestos} €</span>
                </div>
                <div className="flex flex-col md:flex-row justify-between mt-0.5 md:mt-4  bg-black text-white p-4">
                  <span>Total</span>
                  <span>{total} €</span>
                </div>
                <div className="flex flex-col md:flex-row justify-between mt-0.5  bg-gray-200 p-4 col-span-2">
                  <span>Artículos</span>
                  <span>{totalArticulos}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center mt-4">
          <div className="flex items-center justify-between w-96">
            <button
              className="border-1 rounded-xl bg-pink-500 hover:bg-pink-600 text-white p-2 text-xs md:text-sm"
              onClick={togglePopup}
            >
              Cancelar
            </button>
            <button
              className={`border-1 rounded-xl text-white p-2 text-xs md:text-sm ${
                isOrderReady ? "bg-pink-500 hover:bg-pink-600" : "bg-gray-400"
              }`}
              onClick={handleOrderSubmit}
              disabled={!isOrderReady}
            >
              Realizar pedido
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  direcciones: state.contact.direcciones,
});

export default connect(mapStateToProps, {})(ResumeCart);
